<template>
  <b-modal
    v-model="showUser"
    centered
    title="增加机构审核用户"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">审核范围</label>
      <div class="col-sm-10 p-0 flexList">
        <el-checkbox-group v-model="chooseChecks">
          <el-checkbox
            v-for="(check, i) in checkPowerList"
            :label="check.id"
            :key="i"
            >{{ check.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <yz-search
      :data="search"
      v-model="pageData"
      @seachList="getList"
    ></yz-search>
    <div class="table-responsive border mt-3">
      <el-checkbox-group
        v-model="chooseOrgPerson"
        style="max-height: 300px; overflow-y: auto"
      >
        <table class="table light-table table-hover">
          <thead class="thead-light">
            <tr>
              <th style="width: 5%">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  class="mr-1"
                  @change="selectAll"
                >
                </el-checkbox
                >全选
              </th>
              <th style="width: 10%">所属单位</th>
              <th style="width: 7%">登录账号</th>
              <th style="width: 6%">用户姓名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, index) in tableList" :key="index">
              <td>
                <el-checkbox :label="obj.yhzh" class="mr-1">{{
                  index + 1
                }}</el-checkbox>
              </td>
              <td>{{ obj.dwmc }}</td>
              <td>
                <a
                  href="javascript:;"
                  @click="$yzAdminUserInfo().show(obj.yhzh)"
                  class="font-blue"
                  >{{ obj.yhzh }}</a
                >
              </td>
              <td>{{ obj.xm }}</td>
            </tr>
          </tbody>
        </table>
      </el-checkbox-group>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addUser">
        确定添加
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getDicts} from "@/api/common/dict.js";
import yzSearch from "@/components/form/yzSearch.vue";
import {getUserManagement} from "@/api/admin/user/institutionManage";
import {addCheckPower} from "@/api/admin/exam/checkPower";
export default {
  data() {
    return {
      checkAll: false,
      chooseChecks: [],
      chooseOrgPerson: [],
      checks: [
        {name: "报名资格审核", id: 1},
        {name: "报名照片审核", id: 2},
        {name: "学历审核", id: 3},
        {name: "导出报名审核", id: 4},
        {name: "修改审核结果", id: 5},
        {name: "锁定审核", id: 6},
      ],
      isIndeterminate: false,
      search: [],
      pageData: {
        pageSize: 9999
      },
      dict: {},
      tableList: [],
      showUser: false
    };
  },
  methods: {
    show(){
      let store = this.getStore("chooseChecks")
      if (store){
        this.chooseChecks = store.split(",")
      } else {
        this.chooseChecks = []
      }
      this.chooseOrgPerson = []
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    selectAll(e) {
      this.chooseOrgPerson = e
        ? this.tableList.map((item) => {
            return item.yhzh;
          })
        : [];
      this.isIndeterminate = e;
    },
    getList() {
      // this.pageData["dwdm"] = this.$route.query["dwdm"];
      this.pageData["dwmc"] = this.$route.query["dwmc"];
      this.pageData.shzt = 1
      getUserManagement("org", this.pageData).then((res) => {
        if (res.status) {
          this.tableList = res.data;
          this.pageData.total = res.data.total;
        }
      });
    },
    addUser() {
      let chooseOrgList = this.tableList.filter((item) => {
        delete item["createTime"];
        delete item["modifyTime"];
        item.yhxm = item.xm;
        item.ksbmbh = this.$route.query.ksbmbh;
        item.ksmc = this.$route.query.ksmc;
        item.shfl = this.$route.query.shfl;
        if(this.$route.query.dwdm) {
          item.dwdm = this.$route.query.dwdm;
          item.dwmc = this.$route.query.dwmc;
        }
        item.shlb = this.chooseChecks.join(",");
        return this.chooseOrgPerson.some((org) => {
          return org == item.yhzh;
        });
      });
      addCheckPower(chooseOrgList).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "新增成功!",
          });
          this.hide()
          this.$emit("success");
        }
      });
    },
  },
  components: {
    yzSearch,
  },
  mounted() {
    this.getList();
    getDicts("dict_xzqh", "ssjg", "bm").then((res) => {
      this.dict = res;
      this.search = [
        {
          title: "姓名/账号/身份证件号",
          bound: "keyword",
          type: "input",
        },
      ];
    });
  }
};
</script>

<style></style>
