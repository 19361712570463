<template>
  <b-modal
    v-model="showUser"
    centered
    title="批量预置审核范围"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label style="width: 16%">审核范围</label>
      <div class="col-sm-10 p-0 flexList">
        <el-checkbox-group v-model="chooseChecks">
          <el-checkbox
            v-for="(check, i) in checkPowerList"
            :label="check.id"
            :key="i"
            >{{ check.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 16%">账号规则</label>
      <div class="col-sm-10 p-0 flexList">
        <el-radio v-model="chooseRule" :label="1"
          >采用机构+单位+序号，如：10613010102</el-radio
        >
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 16%"></label>
      <div class="col-sm-10 p-0 flexList">
        <el-radio v-model="chooseRule" :label="2"
          >采用机构+单位+序号，如：达州市教育局01</el-radio
        >
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 16%">账号数量</label>
      <div class="col-sm-10 p-0 flexList">
        <input
          placeholder="账号数量"
          class="h30 form-control border-blue mr-2"
          v-model="pageData.zhsl"
          type="number"
          style="width: 156px"
        />
      </div>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addUser">
        确定添加
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getDicts} from "@/api/common/dict.js";
import {addCheckPowerBatch} from "@/api/admin/exam/checkPower";

export default {
  data() {
    return {
      checkAll: false,
      chooseChecks: [],
      chooseOrgPerson: [],
      chooseRule: 1,
      checks: [
        {name: "报名资格审核", id: 1},
        {name: "报名照片审核", id: 2},
        {name: "学历审核", id: 3},
        {name: "导出报名审核", id: 4},
        {name: "修改审核结果", id: 5},
        {name: "锁定审核", id: 6},
      ],
      isIndeterminate: false,
      search: [],
      pageData: {
        jgdm: "",
        jgmc: "",
      },
      dict: {},
      tableList: [],
      showUser: false,
    };
  },
  methods: {
    show(){
      let store = this.getStore("chooseChecks")
      if (store){
        this.chooseChecks = store.split(",")
      } else {
        this.chooseChecks = []
      }
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    addUser() {
      this.pageData["scgz"] = this.chooseRule;
      this.pageData["shlb"] = this.chooseChecks.join(",");
      this.pageData.shfl = this.$route.query.shfl;
      this.pageData["ksbmbh"] = this.$route.query.ksbmbh;
      this.pageData["ksmc"] = this.$route.query.ksmc;
      this.pageData["dwdm"] = this.$route.query.dwdm;
      this.pageData["dwmc"] = this.$route.query.dwmc;
      addCheckPowerBatch(this.pageData).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "新增成功!",
          });
          this.hide()
          this.$emit("success");
        }
      });
    },
  },
  components: {},
  mounted() {
    getDicts("bm").then((res) => {
      this.dict = res;
      let dwdm = this.$route.query.dwdm;
      this.dict.bm.forEach((item) => {
        if (item.value == dwdm) {
          this.pageData["jgmc"] = item.details.jgmc;
          this.pageData["jgdm"] = item.details.jgdm;
          this.pageData["dwdm"] = item.details.dwdm;
          this.pageData["dwmc"] = item.details.dwmc;
        }
      });
    });
  },
};
</script>

<style></style>
