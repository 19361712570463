<template>
  <b-modal
    v-model="showUser"
    centered
    title="编辑机构审核用户"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">审核范围</label>
      <div class="col-sm-10 p-0 flexList">
        <el-checkbox-group v-model="chooseChecks">
          <el-checkbox
            v-for="(check, i) in checkPowerList"
            :label="check.id"
            :key="i"
            >{{ check.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button
        type="button"
        class="btn btn-info h30 w-md mr-3"
        @click="editUser"
      >
        确定修改
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {
  getCheckPowerDetails,
  editCheckPowerDetails,
} from "@/api/admin/exam/checkPower";
export default {
  data() {
    return {
      showUserComp: false,
      chooseChecks: [],
      form: {},
      showUser: false,
      cid: "",
    };
  },
  methods: {
    show(...cid){
      if (!cid||cid.length == 0) {
        return
      }
      if (cid.length == 1){
        this.cid = cid[0]
        getCheckPowerDetails(this.cid).then((res) => {
          if (res.status) {
            this.form = res.data;
            this.chooseChecks = this.form.shlb.split(",");
            this.$forceUpdate();
            this.showUser = true
          }
        });
      } else {
        this.form.cid = cid
        let store = this.getStore("chooseChecks")
        if (store){
          this.chooseChecks = store.split(",")
        } else {
          this.chooseChecks = []
        }
        this.showUser = true
      }
    },
    hide(){
      this.showUser = false
    },
    editUser() {
      this.form.shlb = this.chooseChecks.join(",");
      let cid = this.form.cid
      delete this.form.cid
      editCheckPowerDetails(cid, this.form).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.hide()
          this.$emit("success");
        }
      });
    },
  },
  components: {},
  mounted() {},
};
</script>

<style></style>
