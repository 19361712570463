<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
.modal-title /deep/ {
  text-align: center !important;
  width: 100% !important;
  padding: auto !important;
}

/deep/.el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
}
</style>
<script>
import Layout from "@/views/layouts/main";
import CheckHeader from "@/components/check-header";
import {
  getCheckPowerPage,
  checkPowersToNames,
  deleteCheckPowerDetails,
} from "@/api/admin/exam/checkPower";
import addUserVue from "./add-user.vue";
import addUserBatchVue from "./add-user-batch.vue";
import editUserVue from "./edit-user.vue";
import YzSearch from "@/components/form/yzSearch.vue";
import Vue from "vue";
Vue.prototype.checkPowersToNames = checkPowersToNames;
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    CheckHeader,
    addUserVue,
    addUserBatchVue,
    editUserVue,
    YzSearch,
  },
  data() {
    return {
      title: "考试报名信息审核  / ",
      subTitle: "切换报名",
      ksbmbh: "",
      ksmc: "",
      checDiv: false,
      items: [],
      defaultvalue: 1,
      years: [],
      currentpage: 1,
      showmodal: false,
      select: {},
      checkData: [],
      examInfo: {},
      tableList: [],
      bmh: "",
      info: {},
      checkType: "",
      checkStatus: 0,
      checkMemo: "",
      dwList: [],
      zwList: [],
      examDiv: false,
      showItem: false,
      examList: [],
      checkList: [],
      examParam: {
        ksnf: new Date().getFullYear(),
        ksmc: "",
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      dataList: [],
      show1: false,
      show2: false,
      show3: false,
      chooseCid: "",
      search: [],

      isIndeterminate: false,
      checkAll: false,
      checkedCities: []
    };
  },
  methods: {
    searchClick() { //搜索时先将分页的页码归1
      this.pageData.pageNum = 1; //当前页
      this.getList() //渲染数据的函数
    },
    //  获取审核人列表
    getList(isExport=false) {
      this.checkedCities = []
      // this.pageData.dwdm = this.$route.query.dwdm;
      this.pageData.dwmc = this.$route.query.dwmc;
      this.pageData.ksbmbh = this.$route.query.ksbmbh;
      this.pageData.shfl = this.$route.query.shfl;
      this.pageData.isExport = isExport;
      getCheckPowerPage(this.pageData).then((res) => {
        if (res.status) {
          this.dataList = res.data;
          this.pageData.total = res.total;
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize= res.pageSize;
        }
      });
    },
    // 删除
    deleteCheckPowerDetails(cid) {
      this.$confirm(`是否确认删除该审核人员?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCheckPowerDetails(cid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        });
      });
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },
    handleCheckedCitiesChange(){
      console.log(this.checkedCities)
    },
    handleCheckAllChange(e){
      this.isIndeterminate = e
      this.checkedCities = e?this.dataList:[]
    }
  },
  mounted() {
    this.getList()
    this.search = [
      {
        title: "账号/姓名",
        bound: "keyword",
        type: "input",
      },
    ];
    this.items = [
      {
        text: "用户首页",
        href: "/admin",
      },
      this.$route.query['shfl'] == 1?{
        text: "审核单位",
        href: "/admin/examinationDepartment",
      }:{
        text: "审核单位",
        href: "/admin/examinationDepartmentByLocal",
      },
      {
        text: "单位人员管理",
        active: true,
      },
    ]
  },
};
</script>

<template>
  <Layout>
    <CheckHeader
      :title="title"
      :title2="$route.query.ksmc + '/' + $route.query.dwmc"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 check-table-top">
              <div class="flexList" style="flex: 1">
                <yz-search
                  :data="search"
                  v-model="pageData"
                  @seachList="searchClick"
                ></yz-search>
                <b-button
                  variant="outline-info"
                  class="flexList mr-2 condition"
                  @click="$refs.addUser.show()"
                  ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                  >添加用户</b-button
                >
                <b-button
                  variant="outline-info"
                  class="flexList mr-2 condition"
                  @click="$refs.addUserBatch.show()"
                  ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                  >批量增加</b-button
                >
                <b-button
                    variant="outline-info"
                    class="flexList mr-2 condition"
                    @click="$refs.editUserBatch.show(...checkedCities.map(i=>{return i.cid}))"
                ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                >批量修改权限</b-button
                >

                <b-button
                    variant="outline-info"
                    class="flexList mr-2 condition"
                    @click="deleteCheckPowerDetails(checkedCities.map(i=>{return i.cid}))"
                ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                >批量删除</b-button
                >

                <router-link
                    class="flexList mr-2 condition"
                    :to="{'path': $route.query['shfl'] == 1 ? '/admin/examinationDepartment' : '/admin/examinationDepartmentByLocal', 'query': {'flag': 1}}">
                  <b-button
                      variant="outline-info"
                      class="flexList mr-2 condition"
                  >返回</b-button
                  >
                </router-link>

              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                     @click="getList(true)"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
              </div>
            </div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <div class="table-responsive border mt-3">
                <table class="table light-table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 3%">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
                      </th>
<!--                      <th style="width: 10%">所属机构</th>-->
                      <th style="width: 10%">审核单位</th>
                      <th style="width: 15%">登录账号</th>
                      <th style="width: 15%">用户姓名</th>
                      <th>审核权限</th>
<!--                      <th style="width: 15%">审核类别</th>-->
                      <th style="width: 8%">更新时间</th>
                      <th style="width: 8%">更新人</th>
                      <th style="width: 5%">操作</th>
                    </tr>
                  </thead>
                    <tbody>
                        <tr v-for="(item, i) in dataList" :key="i">
                        <td>
                            <el-checkbox :label="item" :key="i">{{(pageData.pageNum - 1) * pageData.pageSize +  i + 1}}</el-checkbox>
                        </td>
<!--                        <td>-->
<!--                          {{ item.jgmc }}-->
<!--                        </td>-->
                        <td>{{ item.dwmc }}</td>
                        <td>{{ item.yhzh }}</td>
                        <td>{{ item.yhxm }}</td>
                        <td>{{ checkPowersToNames(item.shlb) }}</td>
<!--                        <td>{{ getCheckType(item.shfl) }}</td>-->
                        <td>{{ formatDate(item.modifyTime) }}</td>
                        <td>{{ item.modifyUser }}</td>
                        <td class="tab-icon">
                          <a
                            href="javascript:;"
                            @click="
                              $refs.editUserBatch.show(item.cid)
                            "
                            ><i
                              class="iconfont icon-edit-two text-dark align-middle mr-1"
                            ></i></a
                          ><i
                            @click="deleteCheckPowerDetails(item.cid)"
                            class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
            </el-checkbox-group>
            <div class="float-right d-flex">
              <el-pagination
                @size-change="handleSizeChange"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum"
                :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next"
                :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-user-vue ref="addUser" @success="getList"></add-user-vue>
    <add-user-batch-vue ref="addUserBatch" @success="getList"></add-user-batch-vue>
    <edit-user-vue
        ref="editUserBatch"
      @success="getList"
    ></edit-user-vue>
  </Layout>
</template>
